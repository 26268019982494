import Navbar from './Components/Navbar/Navbar';
import Main from './Components/MainPage/Main.jsx';
import MainDesign from './Components/DesignPage/MainDesign.jsx';
import MainUs from './Components/UsPage/MainUs.jsx';
import About from './Components/MainPage/About.jsx';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ScrollToTop } from './ScrollTop.jsx';
import { AnimatePresence } from 'framer-motion'; 
function App() {
  const location = useLocation();
    return (
    <div className='text-white scroll-smooth cursor-none'>
      <ScrollToTop />
      <Navbar />
      <AnimatePresence mode='wait'>
        <Routes location={location} key={location.pathname}>
          <Route index path='/' element={<Main />} />
          <Route path='/#Information' element={<About />} />
          <Route path='/AboutUs' element={<MainUs />} />
          <Route path='/Design' element={<MainDesign />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
